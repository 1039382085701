import React from "react";
import Layout from "../../Layout/Layout";
import Link from "next/link";
import { BsFillCartXFill, BsFillCheckCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdRemoveShoppingCart } from "react-icons/md";
import { MdNotInterested } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const Wishlist = () => {
  let Navigate = useNavigate();

  // Load
  const [load, setLoad] = useState(false);

  const [cartItem, setCartItem] = useState([]);
  // const [productDetails, setProductDetails] = useState([]);
  // const [noItemsMsg,setNoItemsMsg] = useState("");

  const customer_id = JSON.parse(localStorage.getItem("customer_id"));
  // console.log("==============->",customer_id);

  const GetAllCartItems = async () => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + `get-wishlist/${customer_id}`,
      // "get-wishlist/3",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // setCartItem([result.data]);
        if (result.status === 200) {
          // setNoItemsMsg("");
          setCartItem(result.data);
          // console.log("Result of wishlist ->", result.data);
        } else {
          setCartItem([]);
        }
        // else if(result.status === 404) {
        //   // setNoItemsMsg(result.message);
        // }
        // setTotalQuantity(result.data[0].item_qty);
      })
      .catch((error) => console.log("error", error));
    setLoad(false);
  };

  // const getProductById = async (product_id) => {
  //   setLoad(true);
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   await fetch(
  //     process.env.REACT_APP_BASE_URL + `readByID/${product_id}/read`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("Result of product ->", result);
  //       setProductDetails(result.data);
  //     })
  //     .catch((error) => console.log("error", error));
  //   setLoad(false);
  // };

  // useEffect(() => {
  //   console.log("Cart Item ->", cartItem);
  //   setTimeout(() => {
  //       console.log("Waiting....");
  //       if (cartItem.length > 0 && cartItem[0]?.product_id) {
  //         getProductById(cartItem[0].product_id);
  //       } else {
  //         console.log("Cart not updated yet");

  //       }
  //   }, 2000);
  // }, [cartItem]);

  useEffect(() => {
    GetAllCartItems();
  }, []);

  const handleGoAddress = () => {
    setLoad(true);
    Navigate("/address");
  };

  const handleRemoveCartItems = async (id) => {
    console.log("ID =>", id);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    // console.log(id);
    await fetch(
      process.env.REACT_APP_BASE_URL + `delete-wishlist/${id}/${customer_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          toast.success("Deleted successfully", {
            theme: "light",
            autoClose: 300,
          });
        } else {
          toast.error("Something Went Wrong", {
            theme: "light",
            autoClose: 300,
          });
        }
      })
      .catch((error) => console.log("error", error));

    await GetAllCartItems();
  };

  //   const [updatedQty, setUpdatedQty] = useState();

  //   const CartAddQuantity = async (el) => {
  //     let index = 0;
  //     // const values = [...cartItem];
  //     // values[index].item_qty = parseInt(el.item_qty) + 1;
  //     // setCartItem(values);

  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       id: el.id,
  //       item_qty: parseInt(el.item_qty) + 1,
  //     });
  //     console.log(raw);
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     await fetch(process.env.REACT_APP_BASE_URL + "updateQty", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         console.log(result);

  //         if (result.status === 200) {
  //           setCartItem(result.data);
  //         } else {
  //           toast.error("Something Went Wrong", {
  //             theme: "light",
  //             autoClose: "2000",
  //           });
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   };

  //   const CartMinusQuantity = async (el) => {
  //     let index = 0;
  //     // const values = [...cartItem];
  //     // values[index].item_qty = el.item_qty - 1;
  //     // setCartItem(values);
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       id: el.id,
  //       item_qty: el.item_qty - 1,
  //     });
  //     console.log(raw);
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     await fetch(process.env.REACT_APP_BASE_URL + "updateQty", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         console.log(result);
  //         setCartItem(result.data);
  //         if (result.status === 200) {
  //         } else {
  //           toast.error("Something Went Wrong", {
  //             theme: "light",
  //             autoClose: 2000,
  //           });
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   };

  const getTotalCartItems = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `countCartItems/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setCartItem(result.data);
      })
      .catch(
        (error) => {}
        // console.log("error", error)
      );
  };

  const handleAddToCart = async (getProductData) => {
    // if (isLogin !== "Yes") {

    //   // Navigate('/log-cart');

    //   // Construct the product-related data object
    //   console.log("getProductData:", getProductData);
    //   const productData = {
    //     product_id: getProductData.product.id,
    //     item_qty: 1,
    //     item_price: getProductData.product.first_variation.variation_discounted_price,
    //     total_price: getProductData.product.first_variation.variation_discounted_price * 1,
    //     variation_id: getProductData.product.first_variation.id,
    //     product_desc: getProductData.product.product_desc,
    //     product_name: getProductData.product.product_name,
    //     product_base_image: getProductData.product.product_base_image,
    //   };

    //   dispatch(setProductData(productData));

    //   toast.success("Added Successfully", {
    //     theme: "light",
    //     autoClose: 300,
    //   });
    //   // console.log("productData :", productData);
    //   // Convert the productData object to a query string
    //   const queryString = Object.keys(productData)
    //     .map((key) => key + " : " + productData[key])
    //     .join(" , ");

    //   // Navigate to the login page with the productData as query parameters
    //   // Navigate("/login?" + queryString);
    // } else {
    setLoad(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      product_id: getProductData.product.id,
      item_qty: 1,
      item_price:
        getProductData.product.first_variation.variation_discounted_price,
      total_price:
        getProductData.product.first_variation.variation_discounted_price * 1,
      c_id: localStorage.getItem("customer_id"),
      variation_id: getProductData.product.first_variation.id,
    });
    // console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(process.env.REACT_APP_BASE_URL + "new_cart", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === 1) {
          toast.success("Added Successfully", {
            theme: "light",
            autoClose: 100,
          });

          handleRemoveCartItems(getProductData.product.id);
          Navigate("/cart-page");

          setLoad(false);
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });
    getTotalCartItems();
    // }
  };

  const handleNavigateProductDetail = (el) => {
    localStorage.setItem("Product ID", el.product.id);
    console.log("Cart Data id: ", el.product.id);
    console.log("Cart Data FS: ", el.product.product_name);
    const formattedProductName = el.product.product_name
    .toLowerCase() // Step 1: Convert to lowercase
    .replace(/\s+/g, "-") // Step 2: Replace spaces with dashes
    .replace(/[^a-z0-9-]/g, "");// Remove any non-alphanumeric characters except hyphens

    Navigate(`/Product/${formattedProductName}`, {
      state: { id: el.product.id }, // Pass the id in state
    });
  };

  return (
    <>
      {console.log("Cart length", cartItem.length)}
      {console.log("Cart ===>", cartItem)}
      {load ? (
        <div className="h-screen bg-white"></div>
      ) : (
        <Layout>
          <div className="">
            <div className="flex flex-col md:flex-col lg:flex-row p-1 md:p-10 lg:p-10">
              <div className="bg-white rounded-2xl w-full mb-5">
                <div className="p-10 font-bold text-3xl">Wish List</div>
                <hr className="border-[1px] mx-5 border-[#008000]" />
                {cartItem.length > 0 ? (
                  cartItem.map((el, index) => {
                    return (
                      <div key={index}>
                        <div className="flex p-1 pb-5">
                          <div
                            className="ml-2 md:ml-5 lg:ml-5 mt-7 md:mt-4 lg:mt-4"
                            onClick={() => handleNavigateProductDetail(el)}
                          >
                            <img
                              src={
                                process.env.REACT_APP_MEDIA_BASE_URL +
                                el.product?.product_base_image
                              }
                              alt="azamDeal"
                              className="object-scale-down h-[190px] w-[150px] md:h-[185px] lg:h-[170px] rounded"
                            />
                          </div>

                          {/* Second Flex */}
                          <div className="w-2/3  p-5">
                            <div className="font-bold text-lg flex justify-between w-[90%]">
                              <p>{el.product?.product_name}</p>
                              <div className="justify-end hidden md:block lg:block font-bold text-sm">
                                <p>
                                  <span className="text-[#008000] text-xl">
                                    ₹
                                    {
                                      el.product?.first_variation
                                        ?.variation_discounted_price
                                    }
                                    .00
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="font-semibold text-sm">
                              <p>{el.product?.product_desc.slice(0, 100)}</p>
                            </div>
                            {/* <div className="font-semibold text-sm">
                              {el.product.in_stock === 1 ? (
                                <p className="text-[#008000] font-bold">
                                  In Stock
                                </p>
                              ) : (
                                <p className="text-red-500">Out of Stock</p>
                              )}
                            </div> */}
                            {/* <div className="flex justify-between items-center">
                              <div className="flex items-center font-bold text-sm mt-3">
                                <p className="mr-2 hidden md:block lg:block">
                                  Qty:{" "}
                                </p>
                                <div className="flex items-center border text-[#008000] border-emerald-200 rounded-md p-1">
                                  <button
                                    onClick={() => CartMinusQuantity(el)}
                                    disabled={el.item_qty < 2}
                                  >
                                    <AiOutlineMinus className="text-lg md:text-2xl lg:text-2xl font-semibold mx-2" />
                                  </button>
                                  <span className="text-center text-black px-5 border border-t-white border-b-white border-x-[#008000]">
                                    {parseInt(el.item_qty)}
                                  </span>
                                  <button onClick={() => CartAddQuantity(el)}>
                                    <AiOutlinePlus className="text-lg md:text-2xl lg:text-2xl font-semibold mx-2" />
                                  </button>
                                </div>
                              </div>
                              <div className="font-bold text-sm block md:hidden lg:hidden mt-2">
                                <p>
                                  <span className="text-[#008000] text-xl">
                                    ₹{el.item_price}
                                    .00
                                  </span>
                                </p>
                              </div>
                            </div> */}
                            <div className="flex mt-10 w-[90%]">
                              <button
                                className="p-2 w-full bg-red-500 md:bg-white lg:bg-white md:text-red-500 lg:text-red-500 outline md:outline-red-500 lg:outline-red-500 text-white font-semibold mt-2 rounded-md"
                                onClick={() =>
                                  handleRemoveCartItems(el.product.id)
                                }
                              >
                                Remove
                              </button>
                              <button
                                onClick={() => handleAddToCart(el)}
                                className="text-sm leading-4 inline-flex mt-2 items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-serif text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 ml-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-[#008000] hover:bg-emerald-600 w-full h-12"
                              >
                                Add To Cart
                              </button>
                            </div>
                          </div>
                          {/* Third Flex */}
                          {/* <div className="justify-end hidden md:block lg:block font-bold text-sm mt-2">
                            <p>
                              <span className="text-[#008000] text-xl">
                                ₹{el.product?.first_variation?.variation_discounted_price}.00
                              </span>
                            </p>
                          </div> */}
                        </div>
                        <hr className="border-[0.5px] border-gray-200 mx-5" />
                      </div>
                    );
                  })
                ) : (
                  <div className="bg-white p-5 rounded-2xl w-full md:w-2/3 lg:w-2/3">
                    <div className="flex justify-around items-center">
                      <div>
                        <MdNotInterested className="text-9xl text-gray-500 " />
                      </div>
                      <div>
                        <p className="text-lg font-semibold text-gray-700">
                          <span className="font-bold">
                            No..! Items Available
                          </span>
                          <br /> Please Add items to wishlist...!
                        </p>
                        <div className="flex mt-5 justify-center"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="lg:block sticky top-28 p-5 md:p-10 bg-white ml-5 md:ml-10 h-64 rounded-2xl shadow-xl">
                <BsFillCheckCircleFill className="text-sm text-[#008000]" />
                <span className="text-center text-sm text-[#008000]">
                  Orders Above 499/- are eligible for free Delivery.
                </span>
                <p className="text-xl font-semibold mt-2">
                  SubTotal:{" "}
                  <span>
                    ({totalItem} {""} Items):{" "}
                    <span className="font-bold text-[#008000]">
                      {"₹" + totalPrice + "/-"}
                    </span>
                  </span>
                </p>
                
                <div className="mt-5">
                  <button
                    type="submit"
                    className="w-full p-2 bg-[#008000] hover:bg-green-600 text-white rounded-md font-semibold"
                    onClick={handleGoAddress}
                    disabled={cartItem.length === 0}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={load}
          >
            <CircularProgress color="success" />
          </Backdrop>
        </Layout>
      )}
      <ToastContainer />
    </>
  );
};

export default Wishlist;
