import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoArrowForward, IoReturnUpBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../../Layout/Layout";
import InputArea from "../Pages/Form/InputArea";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const AddAddress = () => {
  let Navigate = useNavigate();

  // Create Address

  const [address, setAdress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [label, setLabel] = useState(null);
  const [fName, setFName] = useState(null);
  const [lName, setLName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);

  const [load, setLoad] = useState(false);

  const [fNameError, setFNameError] = useState(""); // First Name Error
  const [lNameError, setLNameError] = useState(""); // Last Name Error
  const [mobileError, setMobileError] = useState(""); // Mobile Number Error
  const [emailError, setEmailError] = useState(""); // Email Error
  const [addressError, setAddressError] = useState(""); // Address Error
  const [cityError, setCityError] = useState(""); // City Error
  const [stateError, setStateError] = useState(""); // State Error
  const [countryError, setCountryError] = useState(""); // Country Error
  const [pincodeError, setPincodeError] = useState("");

  const [IfClicked, setIfClicked] = useState(0);

  const [countryid, setCountryid] = useState(101);
  const [stateid, setStateid] = useState();
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    GetCountries()
      .then((result) => {
        setCountriesList(result.filter((country) => country.name === "India"));

        const india = result.find((country) => country.name === "India"); // Get India as default
        if (india) {
          setCountryid(india.id);
          setCountry(india.name);

          // Fetch states for the default country (India)
          GetState(india.id)
            .then((states) => {
              setStateList(states);

              const defaultState = stateList.find(
                (state) => state.name === "Maharashtra"
              );
              setStateid(defaultState.id);

              GetCity(india.id, defaultState.id)
                .then((cities) => {
                  setCityList(cities);
                })
                .catch((err) =>
                  console.error("Error fetching default cities:", err)
                );
            })
            .catch((err) => console.error("Error fetching states:", err));
        }
      })
      .catch((err) => console.error("Error fetching countries:", err));
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = countriesList[e.target.value];
    setCountryid(selectedCountry.id);
    setCountry("India");
    GetState(selectedCountry.id).then((result) => {
      setStateList(result);
      // console.log("STATES", result);
    });
  };

  const handleStateChange = (e) => {
    const defaultState = stateList.filter(
      (state) => state.id == e.target.value
    );
    setStateid(defaultState[0].id);
    setState(defaultState[0].name);
    GetCity(countryid, defaultState[0].id).then((result) => {
      setCityList(result);
    });
  };

  const handleCityChange = (e) => {
    const city = cityList.filter((city) => city.id == e.target.value);
    // const selectedCity = e.target.value;
    setCityid(city[0].id);
    setCity(city[0].name);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    setIfClicked(1);

    let errorFlag = false;

    if (!fName) {
      setFNameError("This field is required");
      errorFlag = true;
    } else {
      setFNameError("");
    }

    if (!lName) {
      setLNameError("This field is required");
      errorFlag = true;
    } else {
      setLNameError("");
    }

    if (!mobile) {
      setMobileError("This field is required");
      errorFlag = true;
    } else {
      setMobileError("");
    }

    if (!email) {
      setEmailError("This field is required");
      errorFlag = true;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      errorFlag = true;
    } else {
      setEmailError("");
    }

    if (!address) {
      setAddressError("This field is required");
    } else {
      setAddressError("");
    }

    if (!city) {
      setCityError("This field is required");
      errorFlag = true;
    } else {
      setCityError("");
    }

    if (!state) {
      setStateError("This field is required");
      errorFlag = true;
    } else {
      setStateError("");
    }

    if (!country) {
      setCountryError("This field is required");
      errorFlag = true;
    } else {
      setCountryError("");
    }

    if (!pincode) {
      setPincodeError("This field is required");
      errorFlag = true;
    } else {
      setPincodeError("");
    }

    if (!errorFlag) {
      // If there are no errors, proceed with API request
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        address_line: address,
        city: city,
        state: state,
        country: country,
        pincode: pincode,
        label: label ? label : "Home",
        customer_id: localStorage.getItem("customer_id"),
        first_name: fName,
        last_name: lName,
        mobile_number: mobile,
        email: email,
      });

      console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "add_address",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === 200) {
            toast.success("Address Added Successfully", {
              theme: "light",
              autoClose: 2000,
            });
            setLoad(false);
            Navigate("/address");
          } else {
            toast.error("Something went wrong", {
              theme: "light",
              autoClose: 2000,
            });
            setLoad(false);
          }
        })
        .catch((error) => console.log("error", error));

      setTimeout(() => {
        setIfClicked(0);
      }, 2000);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {console.log(fName)}
      <Layout>
        <div className="bg-slate-100 flex justify-center rounded-md" id="fname">
          <div className="flex items-center m-10 p-5 font-bold text-2xl text-gray-800">
            <FaMapMarkerAlt />
            <span className="text-gray-800">Add a new address</span>
          </div>
        </div>
        <div className="mx-2 md:mx-20 lg:mx-72 m-10">
          <div>
            <form className="mt-10">
              <div className="form-group">
                <h2 className="font-semibold font-serif text-base text-gray-700 pb-3">
                  01. Recievers Details
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                  <div className="">
                    <InputArea
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="first name"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {fNameError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {fNameError}
                      </div>
                    )}
                  </div>

                  <div className="">
                    <InputArea
                      label="Last name"
                      name="lastName"
                      type="text"
                      placeholder="last name"
                      value={lName || ""}
                      onChange={(e) => setLName(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {lNameError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {lNameError}
                      </div>
                    )}
                  </div>

                  <div className="">
                    <InputArea
                      label="Email address"
                      name="email"
                      type="email"
                      placeholder="youremail@gmail.com"
                      value={email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {emailError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {emailError}
                      </div>
                    )}
                  </div>

                  <div className="">
                    <InputArea
                      label="Phone number"
                      name="contact"
                      type="number"
                      placeholder="+062-123456789"
                      value={mobile || ""}
                      onChange={(e) => setMobile(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {mobileError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {mobileError}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group mt-12">
                <h2 className="font-semibold font-serif text-base text-gray-700 pb-3">
                  02. Shipping Details
                </h2>

                <div className="col-span-6">
                  <InputArea
                    label="Street address"
                    name="address"
                    type="text"
                    placeholder="address"
                    value={address || ""}
                    onChange={(e) => setAdress(e.target.value)}
                    autoComplete="off"
                    className={`${
                      IfClicked === 1 && fName === ""
                        ? "bg-red-50 border-2 border-red-500"
                        : "bg-white border border-gray-200"
                    } `}
                  />
                  {addressError && (
                    <div className="text-xs text-red-500 font-semibold ml-2">
                      {addressError}
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
                  {/* <div>
                    <InputArea
                      label="City"
                      name="city"
                      type="text"
                      placeholder="Mumbai"
                      value={city || ""}
                      onChange={(e) => setCity(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {cityError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {cityError}
                      </div>
                    )}
                  </div> */}

                  {/* <div>
                    <InputArea
                      label="State"
                      name="country"
                      type="text"
                      placeholder="Maharashtra"
                      value={state || ""}
                      onChange={(e) => setState(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {stateError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {stateError}
                      </div>
                    )}
                  </div> */}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
                  {/* Country Dropdown */}
                  <div>
                    <label className="block text-gray-500 font-medium text-sm leading-none mt-5 mb-2">
                      Country
                    </label>
                    <select
                      onChange={handleCountryChange}
                      value={countryid}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 block w-full p-2.5"
                    >
                      {countriesList.map((item, index) => (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* State Dropdown */}
                  <div>
                    <label className="block text-gray-500 font-medium text-sm leading-none mt-5 mb-2">
                      State
                    </label>
                    <select
                      onChange={handleStateChange}
                      value={stateid}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 block w-full p-2.5"
                    >
                      {stateList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {/* {!stateid && <option >Select an option</option>} */}
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {stateError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {stateError}
                      </div>
                    )}
                  </div>
                </div>
                {/* City Dropdown */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
                  <div>
                    <label className="block text-gray-500 font-medium text-sm leading-none mt-5 mb-2">
                      City
                    </label>
                    <select
                      onChange={handleCityChange}
                      value={cityid}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 block w-full p-2.5"
                    >
                      {cityList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {cityError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {cityError}
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <InputArea
                      label="Pin Code"
                      name="zipCode"
                      type="text"
                      placeholder="123456"
                      maxLength={6}
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      autoComplete="off"
                      className={`${
                        IfClicked === 1 && fName === ""
                          ? "bg-red-50 border-2 border-red-500"
                          : "bg-white border border-gray-200"
                      } `}
                    />
                    {pincodeError && (
                      <div className="text-xs text-red-500 font-semibold ml-2">
                        {pincodeError}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group mt-12">
                <h2 className="font-semibold font-serif text-base text-gray-700">
                  03. Address Label
                </h2>
                <label
                  htmlFor="countries"
                  className="block text-gray-500 font-medium text-sm leading-none mt-5 mb-2"
                >
                  Select an option
                </label>
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 block w-full p-2.5"
                  onChange={(e) => setLabel(e.target.value)}
                  autoComplete="off"
                >
                  <option value="Home" defaultValue>
                    Home
                  </option>
                  <option value="Office">Office</option>
                  <option value="Others">Others</option>
                </select>
                {fNameError && (
                  <div className="text-xs text-red-500 font-semibold ml-2">
                    {fNameError}
                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row lg:flex-row justify-end mt-10">
                <div className="w-full md:w-60 lg:w-60">
                  <button
                    className="bg-indigo-50 border border-indigo-100 rounded py-3 text-center text-sm font-medium text-gray-700 hover:text-gray-800 hover:border-gray-300 transition-all flex justify-center font-serif w-full"
                    onClick={handleBack}
                  >
                    <span className="text-xl mr-2">
                      <IoReturnUpBackOutline />
                    </span>
                    Cancel
                  </button>
                </div>
                <div className="ml-0 mt-4 md:mt-0 lg:mt-0 md:ml-5 lg:ml-5 w-full md:w-52 lg:w-52">
                  <button
                    onClick={handleAddAddress}
                    type="submit"
                    className="bg-emerald-500 hover:bg-emerald-600 border border-emerald-500 transition-all rounded py-3 text-center text-sm font-serif font-medium text-white flex justify-center w-full"
                  >
                    <span className="flex justify-center text-center">
                      Confirm
                      <span className="text-xl ml-2">
                        <IoArrowForward />
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <ToastContainer />
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={load}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default AddAddress;
