// import { createStore } from 'redux';
// import rootReducer from './reducer';

// const initialState = {
//   count: 0, // This is just a placeholder, replace it with your actual initial state structure.
//   productData: [],
// };

// const store = createStore(rootReducer, initialState);
// export default store;

// import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import rootReducer from './reducer'; // Your combined reducers

const persistConfig = {
  key: 'root', // The key for storage
  storage, // The storage engine (localStorage)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
