import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import PageHeader from "./PageHeader";
import { Helmet } from "react-helmet";

const TermsConditions = () => {
  const [apiLoader, setApiLoader] = useState(true);

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "#008000" : "#008000",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      setApiLoader(false);
    }, 2000);
  }, []);

  return (
    <div>
      <Helmet>
        <title>AZAMDEAL - Terms & Conditions</title>
        <meta
          name="description"
          content="AZAMDEAL is an startup incubated in AZAM Campus Pune. It's part of University of Pune's Start-up promotion cell."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="AZAMDEAL is an startup incubated in AZAM Campus Pune. It's part of University of Pune's Start-up promotion cell."
        />
        <meta property="og:url" content={window.location.href} />
        {/* <meta
          property="og:image"
          content={`${process.env.REACT_APP_MEDIA_BASE_URL}${changeImage}`} // Correct base URL
        /> */}
      </Helmet>
      <Layout
        title="Shipping Policy"
        description="Last updated: October 11, 2023"
      >
        {apiLoader && (
          <div className="h-screen bg-white">
            <Box sx={{ flexGrow: 1 }} className="absolute top-[50%] left-[50%]">
              <FacebookCircularProgress />
            </Box>
          </div>
        )}
        {apiLoader === true ? (
          <div className="h-screen bg-white" />
        ) : (
          <>
            <PageHeader title="Terms & Conditions" />
            <div className="p-10">
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginBottom: "1%",
                    fontFamily: "serif",
                  }}
                >
                  Terms & Conditions
                </p>

                {/* <p style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '1%' }}>
                                    <a href="AZAMDEAL.com" style={{ color: 'blue' }}>AZAMDEAL.com</a> Terms and Conditions
                                </p> */}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "1%",
                  }}
                >
                  Welcome to{" "}
                  <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                    AZAMDEAL.com
                  </a>
                </p>
              </div>

              <div style={{ marginBottom: "2%" }}>
                {/* <p style={{ fontWeight: 'bold', fontSize: '29px', fontFamily: 'serif', marginBottom: '0.5%' }}>Order Processing</p> */}
                <ul
                  style={{
                    listStyleType: "disc",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginLeft: "1%",
                    marginTop: "0.5%",
                  }}
                >
                  <li style={{ marginBottom: "0.5%" }}>
                    The following rules govern the use of this website. By
                    using, adding information, or visiting this website, you
                    expressly agree that you have read, understood and are bound
                    by the terms and conditions and all relevant laws and policy
                    governing this site, including the supplementary or amended
                    terms or condition as defined from time to time,
                    irrespective of how and when you have subscribed to the
                    services.
                  </li>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "29px",
                      fontFamily: "serif",
                      marginBottom: "0.5%",
                    }}
                  >
                    Shipping & Delivery Policy for Products
                  </p>
                  <li style={{ marginBottom: "0.5%" }}>
                    See Here:{" "}
                    <a
                      href="https://www.azamdeal.com/ShippingPolicy"
                      style={{ color: "blue" }}
                    >
                      https://www.azamdeal.com/ShippingPolicy
                    </a>
                  </li>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "29px",
                      fontFamily: "serif",
                      marginBottom: "0.5%",
                    }}
                  >
                    Privacy Policy
                  </p>
                  <li style={{ marginBottom: "0.5%" }}>
                    See Here:{" "}
                    <a
                      href="https://www.azamdeal.com/PrivacyPolicy"
                      style={{ color: "blue" }}
                    >
                      https://www.azamdeal.com/PrivacyPolicy
                    </a>
                  </li>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "29px",
                      fontFamily: "serif",
                      marginBottom: "0.5%",
                    }}
                  >
                    Return and Refund Policy
                  </p>
                  <li style={{ marginBottom: "0.5%" }}>
                    See Here:{" "}
                    <a
                      href="https://www.azamdeal.com/ReturnAndRefundPolicy"
                      style={{ color: "blue" }}
                    >
                      https://www.azamdeal.com/ReturnAndRefundPolicy
                    </a>
                  </li>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "29px",
                      fontFamily: "serif",
                      marginBottom: "0.5%",
                    }}
                  >
                    Call Center Policy
                  </p>
                  <li style={{ marginBottom: "0.5%" }}>
                    It is strongly preferred that customers communicate through
                    WhatsApp text messages or via email provided on the "Contact
                    Us" page of{" "}
                    <a href="ZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>
                    . While phone calls are not appreciated, in case of
                    emergencies, customers may contact via the customer care
                    contact number. Our customer support primarily operates
                    through WhatsApp or email.
                  </li>

                  {/* <p style={{ fontWeight: 'bold', fontSize: '29px', fontFamily: 'serif', marginBottom: '0.5%' }}>Call Center Policy</p> */}
                  <li style={{ marginBottom: "0.5%" }}>
                    Customers are requested to maintain communication through
                    one channel and one number. The{" "}
                    <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>{" "}
                    team reserves the right not to respond if a customer is
                    found spamming on multiple channels. Once we receive your
                    query, it is forwarded to the relevant department, and their
                    response is sent to you via WhatsApp.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    This practice enables us to provide accurate information
                    regarding your query.{" "}
                    <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>{" "}
                    typically responds to all relevant queries within 24 hours.
                    Customers are expected to be precise and clear in their
                    communication. For queries regarding existing orders,
                    customers must provide their order number, name, and exact
                    query and concern to receive a response from our team.
                    Pointless or irrelevant queries will not be addressed.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    Customers are required to track their orders through the
                    "Track Order" link provided on the website. Queries
                    regarding order status will not be entertained by the{" "}
                    <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>{" "}
                    team.{" "}
                    <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>{" "}
                    reserves the right to not respond and/or block customers who
                    disregard instructions provided by our customer service
                    agents and continue to spam the inbox or call center
                    numbers.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    In the event of abusive behavior towards customer care
                    agents and/or attempts to defame the company in public,{" "}
                    <a href="AZAMDEAL.com" style={{ color: "blue" }}>
                      AZAMDEAL.com
                    </a>{" "}
                    reserves the full right to cancel orders and/or forfeit
                    payments.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    Quotations provided by the company are valid for 24 hours,
                    subject to stock availability.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    Product images displayed are for reference purposes only and
                    may change due to lot changes, cropping, and availability in
                    the market at the time.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    The company securely maintains all customer interaction
                    records for 48 hours only. Beyond 48 hours, the company is
                    not liable to retain these records. Customers are advised to
                    address their issues within 48 hours.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    After placing an order, if a customer is unavailable at the
                    provided address for any reason, they are responsible for
                    managing courier/post delivery arrangements themselves,
                    without pressuring the company for early/customized
                    (time-based) delivery.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    All complaints/issues regarding products or services are
                    addressed within 24 hours of delivery. The company is not
                    liable for anything beyond that time frame.
                  </li>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "29px",
                      fontFamily: "serif",
                      marginBottom: "0.5%",
                    }}
                  >
                    Zero Tolerance towards Abusive Customers:
                  </p>

                  <li style={{ marginBottom: "0.5%" }}>
                    In response to abusive customers in the e-commerce
                    ecosystem, our company adopts a zero-tolerance policy
                    towards abusive behavior.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    Customers are advised to be courteous, adhere to company
                    policies, and possess e-commerce/digital/IT knowledge before
                    engaging in disputes with our customer support executives.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    Abusive behavior from customers may result in order
                    cancellation, forfeiture of payment, if any, and prohibition
                    from placing further orders.
                  </li>

                  <li style={{ marginBottom: "0.5%" }}>
                    If a customer unjustly defames the company in public or
                    approaches any authority or sends notices complaining
                    against the company without cause, it constitutes a
                    punishable offense under the Indian Penal Code (IPC) Section
                    499, and our company's legal counsel will take appropriate
                    action.
                  </li>

                  {/* <p style={{ fontWeight: 'bold', fontSize: '29px', fontFamily: 'serif', marginBottom: '0.5%' }}>Disclaimer:</p>

                                    <li style={{ marginBottom: '0.5%' }}>The information provided on the website has not been evaluated by the FDA or by us. This data is for informational purposes only and is not intended to diagnose, treat, cure, or prevent any disease. We strongly recommend consulting a qualified Ayurvedic practitioner before starting or following any regimen.</li> */}
                </ul>
              </div>

              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "1%",
                  }}
                >
                  Disclaimer: The information provided on the website has not
                  been evaluated by the FDA or by us. This data is for
                  informational purposes only and is not intended to diagnose,
                  treat, cure, or prevent any disease. We strongly recommend
                  consulting a qualified Ayurvedic practitioner before starting
                  or following any regimen.
                </p>
              </div>
            </div>
          </>
        )}
      </Layout>
    </div>
  );
};

export default TermsConditions;
