import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { result } from "lodash";
import { useLocation } from "react-router-dom";

function TrackOrder(props) {
  const orderStatusData = [
    {
      id: 1,
      status_name: "Order Placed",
    },
    {
      id: 2,
      status_name: "Order Accepted",
    },
    {
      id: 3,
      status_name: "Ready to Pickup",
    },
    {
      id: 4,
      status_name: "Dispatched",
    },
    {
      id: 5,
      status_name: "In Transit",
    },
    {
      id: 6,
      status_name: "Delivered",
    },
    {
      id: 7,
      status_name: "Cancelled",
    },
  ];

  const location = useLocation();

  const { order_id } = location.state || {}; 

  const [orderData, setOrderData] = useState();
  const [currentStatus, setCurrentStatus] = useState(6);
  const [completionPercentage, setCompletionPercentage] = useState(
    (1 / 6) * 100
  );
  const [isCancelled, setIsCancelled] = useState(false);

  //   const isCancelled = currentStatus === 7;

  //   const currentStatus = 4; // Assuming the current status is "Dispatched"
  const expectedCompletion = "October 12, 2019";
  //   const completionPercentage = currentStatus/7;

  const fetchOrderData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    //   order_id: 458,
        order_id: order_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await fetch(process.env.REACT_APP_BASE_URL + "OrderDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOrderData(result.data);
        setCurrentStatus(result?.data?.order_status);
        setCompletionPercentage(((result?.data?.order_status - 1) / 6) * 100);
        if (result?.data?.order_status == 7) setIsCancelled(true);
      });
  };

  useEffect(() => {
    fetchOrderData();
    window.scrollTo(0, 0);

  }, []);

  return (
    <Layout>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-green-600">My Orders</h2>
        <div className="border-b border-green-200 pb-4 mb-6">
          <h3 className="text-xl font-bold mb-2 text-green-600">Order {order_id}</h3>
          {/* <p className="text-gray-600 mb-2">Agent: Sample Agent</p>
          <p className="text-gray-600 mb-4">Task: Buy Property</p> */}
          <div>
            <div className="flex justify-between items-center font-medium mt-4 mb-6">
              <h4
                className={`font-bold ${
                  isCancelled ? "text-red-600" : "text-green-600"
                }`}
              >
                Current Status: {orderStatusData[currentStatus - 1].status_name}
              </h4>
              {/* <p>Expected Completion: {expectedCompletion}</p> */}
            </div>
            <div className="relative mx-10">
              {/* Progress bar */}
              <div className="absolute top-1 transform -translate-y-1/2 w-full h-1 bg-gray-300 z-0" />
              <div
                className={`absolute top-1 transform -translate-y-1/2 h-1  ${
                  isCancelled ? "bg-red-500" : "bg-green-500"
                } z-10`}
                style={{
                    width: isCancelled
                      ? "100%"
                      : `calc(${((currentStatus - 1) / (orderStatusData.length - 1)) * 100}% + 70px)`,
                  }}
                  
              />
              {/* Status indicators */}
              <div className="flex justify-between z-20 relative">
                {orderStatusData.map((status, index) => (
                  <div key={status.id} className="flex flex-col items-end">
                    <div
                      className={`w-4 h-4 rounded-full flex items-center justify-center mt-[-4px] ${
                        isCancelled
                          ? "bg-red-500 text-white"
                          : index < currentStatus
                          ? "bg-green-500 text-white"
                          : "bg-gray-300 text-gray-600"
                      }`}
                    >
                      {/* {index + 1} */}
                    </div>
                    <p className="mt-2 text-sm text-center font-medium text-gray-700">
                      {status.status_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TrackOrder;
