import {
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Grow,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../Layout/Layout";
import Carousal from "../Carousal/Carousal";
import ProductCard from "../Products/ProductCard";
import { Helmet } from "react-helmet";

const Home = () => {
  const [productList, setProductList] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [CartItem, setCartItem] = useState();
  const [openAppSuggestion, setOpenAppSuggestion] = useState(false);
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getTotalCartItems();

    // Check if the app is running inside a WebView (React Native)
    if (window.ReactNativeWebView) {
      setIsWebView(true); // Set flag to disable dialog
    } else {
      const hasDownloadedApp = localStorage.getItem("hasDownloadedApp");

      // Show app suggestion if the user hasn't downloaded the app and it's not a WebView
      if (!hasDownloadedApp) {
        setTimeout(() => {
          setOpenAppSuggestion(true);
        }, 1000); // Show after 1 second
      }
    }
  }, []);

  // Fetch total cart items
  const getTotalCartItems = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `countCartItems/${localStorage.getItem("customer_id")}`,
        { method: "GET" }
      );
      const result = await response.json();
      setCartItem(result.data);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  }, []);

  // Fetch all products
  const getAllProducts = useCallback(() => {
    fetch(
      "https://azamdeal.com/apk_api/azamDeals/public/index.php/api/getAllProducts",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setProductList(result.data);
        setCardSkeleton(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setCardSkeleton(false);
      });
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const numCards = 20;

  const handleClose = () => {
    setOpenAppSuggestion(false);
  };

  const handleDownloadClick = () => {
    // Store the flag in localStorage when the user clicks "Download Now"
    localStorage.setItem("hasDownloadedApp", "true");
  };

  const handleLaterClick = () => {
    // Store the flag in localStorage when the user clicks "Download Now"
    setOpenAppSuggestion(false);
    localStorage.setItem("hasDownloadedApp", "true");
  };

  return (
    <div id="scroll-top">
      <Helmet>
        <title>AZAMDEAL</title>
        <meta
          name="description"
          content="AZAMDEAL is a startup incubated in AZAM Campus Pune. It's part of University of Pune's Start-up promotion cell."
        />
        <meta property="og:title" content="AZAMDEAL" />
        <meta
          property="og:description"
          content="AZAMDEAL is a startup incubated in AZAM Campus Pune. It's part of University of Pune's Start-up promotion cell."
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="https://azamdeal.com/azamlogo.jpg" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://azamdeal.com/Product/Azamdeal-Amla-Candy-Meetha-Amla"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AZAMDEAL" />
        <meta
          property="og:description"
          content="From traditional “Hikmat” to online supply of Precious herbs. Azamdeal is an startup incubated in Azam Campus, Pune."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="azamdeal.com" />
        <meta
          property="twitter:url"
          content="https://azamdeal.com/Product/Azamdeal-Amla-Candy-Meetha-Amla"
        />
        <meta name="twitter:title" content="AZAMDEAL" />
        <meta
          name="twitter:description"
          content="From traditional “Hikmat” to online supply of Precious herbs. Azamdeal is an startup incubated in Azam Campus, Pune."
        />
        <meta name="twitter:image" content="" />

        {/* Add Google Analytics tracking */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QT7NJC266L"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-QT7NJC266L');
          `}
        </script>
      </Helmet>
      <Layout CartItem={CartItem}>
        <div>
          {/* Carousel Section */}
          <div className="bg-white">
            <div className="mx-auto py-5 max-w-screen-2xl px-3 sm:px-10">
              <div className="flex w-full">
                <div className="flex-shrink-0 xl:pr-6 lg:block w-full">
                  <Carousal />
                </div>
              </div>
            </div>
          </div>

          {/* App Suggestion Popup with Grow Animation */}
          {!isWebView && (
            <Dialog
              open={openAppSuggestion}
              onClose={handleClose}
              TransitionComponent={Grow}
              aria-labelledby="app-suggestion-title"
              aria-describedby="app-suggestion-description"
              PaperProps={{
                style: {
                  borderRadius: 15,
                  padding: "20px",
                  backgroundColor: "#fff",
                },
              }}
              maxWidth="xs"
            >
              <DialogTitle
                id="app-suggestion-title"
                style={{ textAlign: "center" }}
              >
                <img
                  src="/azamlogo.jpg"
                  alt="Azamdeal Logo"
                  style={{ height: "78px", marginBottom: "10px" }}
                />
                <span
                  style={{
                    color: "#008000",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Download Our App
                </span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="app-suggestion-description"
                  style={{ textAlign: "center", color: "#555" }}
                >
                  For a better shopping experience, download our mobile app now!
                  Get exclusive deals, faster browsing, and personalized
                  recommendations.
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "8px" }}
              >
                <Button
                  onClick={handleLaterClick}
                  style={{ color: "#008000", borderColor: "#008000" }}
                  variant="outlined"
                >
                  Later
                </Button>
                <Button
                  href="https://play.google.com/store/apps/details?id=com.azamdeal&hl=en" // PlayStore Link
                  style={{
                    backgroundColor: "#008000",
                    color: "white",
                    marginLeft: "10px",
                  }}
                  variant="contained"
                  onClick={handleDownloadClick}
                >
                  Download Now
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {/* Products Section */}
          <div className="bg-gray-50 lg:py-16 py-10 mx-auto max-w-screen-2xl px-3 sm:px-10">
            <div className="mb-10 flex justify-center">
              <div className="text-center w-full lg:w-2/5">
                <h2 className="text-xl lg:text-2xl mb-2 font-serif font-semibold">
                  Select Best Quality Herbs Picked from Jungles for your needs..
                </h2>
                <p className="text-base font-sans text-gray-600 leading-6">
                  See the large variety of Pure and Authentic herbs. No
                  artificial colors or flavor & No use of chemicals in cleaning.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="w-full">
                <div>
                  {cardSkeleton && (
                    <>
                      {[...Array(numCards)].map((_, index) => (
                        <div
                          className="group box-border overflow-hidden rounded-md shadow-sm bg-white relative border"
                          key={index}
                        >
                          <div className="p-3">
                            <Skeleton
                              sx={{ height: 190 }}
                              animation="wave"
                              variant="rectangular"
                              className="rounded-md"
                            />
                            <Skeleton
                              animation="wave"
                              height={15}
                              width="100%"
                              style={{ marginTop: 6 }}
                            />
                            <div className="flex items-center gap-5">
                              <Skeleton
                                animation="wave"
                                height={15}
                                width="30%"
                                style={{ marginTop: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={15}
                                width="30%"
                                style={{ marginTop: 6 }}
                              />
                            </div>
                            <div className="flex justify-between">
                              <Skeleton
                                animation="wave"
                                height={15}
                                width="30%"
                                style={{ marginTop: 8, marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                width="12%"
                                height={50}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <ProductCard productList={productList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Home;
