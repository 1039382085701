const initialState = {
  count: 0, // This is just a placeholder, replace it with your actual initial state structure.
  productData: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENT":
      return { ...state, count: state.count + 1 };

    case "DECREMENT":
      return { ...state, count: state.count - 1 };

    case "SET_PRODUCT_DATA":
      const existingProductIndex = state.productData.findIndex(
        (product) => product.product_id === action.payload.product_id
      );
      let newProductData;
      if (existingProductIndex !== -1) {
        newProductData = state.productData.map((product, index) => {
          if (index === existingProductIndex) {
            return {
              ...product,
              item_qty: product.item_qty + action.payload.item_qty,
              total_price: product.total_price + action.payload.total_price,
            };
          }
          return product;
        });
      } else {
        newProductData = [...state.productData, action.payload];
      }

      console.log("New State with updated productData:", newProductData);
      return { ...state, productData: newProductData };

    case "ADD_QUANTITY":
      const addedProductData = state.productData.map((product) => {
        if (product.product_id === action.payload.product_id) {
          return {
            ...product,
            item_qty: product.item_qty + 1,
            total_price: +product.total_price + +product.item_price,
          };
        }
        return product;
      });

      return { ...state, productData: addedProductData };

    case "REMOVE_QUANTITY":
      const removedProductData = state.productData.map((product) => {
        if (product.product_id === action.payload.product_id) {
          return {
            ...product,
            item_qty: product.item_qty - 1,
            total_price: +product.total_price - +product.item_price,
          };
        }
        return product;
      });

      return { ...state, productData: removedProductData };

    case "REMOVE_PRODUCT":
      const updatedProductData = state.productData.filter(
        (item) => item.variation_id !== action.payload
      );
      return { ...state, productData: updatedProductData };

    case "CLEAR_ALL_DATA":
      return initialState;

    default:
      return state;
  }
};

export default rootReducer;
