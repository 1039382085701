import React, { useEffect, useState } from "react";
import { IoArrowForward, IoReturnUpBackOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { IoIosCash } from "react-icons/io";
import InputArea from "../Pages/Form/InputArea";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";

import {
  Backdrop,
  Box,
  CircularProgress,
  Step,
  stepClasses,
  StepLabel,
  Stepper,
} from "@mui/material";
import moment from "moment";

const steps = ["Shipping Details", "Payment Details"];

const Payment = (props) => {
  let Navigate = useNavigate();

  // Loader
  const [load, setLoad] = useState(false);

  // Hooks
  const [paymentMethod, setPaymentMethod] = useState("PhonePe");
  const [inputError, setInputError] = useState(null);
  const [userAddress, setUserAddress] = useState([]);

  // Order Generator Integration
  const handleGenerateOrder = async () => {
    let CID = await localStorage.getItem("customer_id");
    let AddressID = await localStorage.getItem("Address ID");
    let CouponID = await localStorage.getItem("CouponID");
    let CouponValue = await localStorage.getItem("CouponAmount");
    const formattedDate = moment(new Date()).format("MM/DD/YY");

    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customer_id: CID,
      address_id: AddressID ? AddressID : props.ID,
      coupon_id: CouponID,
      discount_amount: CouponValue,
      payment_mode: paymentMethod ? paymentMethod : "COD",
      payment_status: "Done",
      date: formattedDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "generateOrder",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          toast.success("Order Placed Successfully", {
            theme: "light",
            autoClose: "2000",
          });
          Navigate("/success");
          setLoad(false);
        } else {
          toast.error("Something Went Wrong", {
            theme: "light",
            autoClose: "2000",
          });
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getAllAddress = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }addressByCustomerId/${localStorage.getItem("customer_id")}`,
        requestOptions
      );
      const result = await response.json();
      console.log("User Address: ", result.data);

      if (result.data.length > 0) {
        // Since it's an array, access the first address
        setUserAddress(result.data[0]);
      } else {
        console.log("No address found");
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoad(false);
  };

  useEffect(() => {
    getAllAddress();
    console.log("User Data Payment Page:  ", JSON.stringify(userAddress));
  }, []);

  // PhonePe Payment Integration
  const phonePayPayment = async () => {
    let CID = await localStorage.getItem("customer_id");
    let AddressID = await localStorage.getItem("Address ID");
    let CouponID = await localStorage.getItem("CouponID");
    let CouponValue = await localStorage.getItem("CouponAmount");
    const formattedDate = moment(new Date()).format("MM/DD/YY");
    const calculateValue = await localStorage.getItem("calculateValue");
    const couponMsg = await localStorage.getItem("couponMsg");

    let amount;
    if (couponMsg === "Coupon Applied") {
      if (calculateValue > 500) {
        amount = parseInt(calculateValue) - parseInt(CouponValue);
      } else {
        amount = parseInt(calculateValue) - parseInt(CouponValue) + 40;
      }
    } else {
      if (calculateValue > 500) {
        amount = parseInt(calculateValue);
      } else {
        amount = parseInt(calculateValue) + 40;
      }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      amount: amount + "00",
      Cu_ID: CID,
      address_id: AddressID,
      coupon_id: CouponID,
      discount_amount: CouponValue,
      date: formattedDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://azamdeal.com/apk_api/azamDeals/public/index.php/api/PhonePe",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          window.location.href = result.url;
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const encryptData = (data, key) => {
  //   const encrypted = CryptoJS.AES.encrypt(data, key).toString();
  //   return encrypted;
  // };

  // CCAvenue Payment Integration
  const ccAvenuePayment = async () => {
    const ccAvenueUrl =
      "https://azamdeal.com/apk_api/azamDeals/public/index.php/api/Ccavenue";

    // Payment details to send directly
    let CID = await localStorage.getItem("customer_id");
    let AddressID = await localStorage.getItem("Address ID");
    let CouponValue = await localStorage.getItem("CouponAmount");
    const formattedDate = moment(new Date()).format("MM/DD/YY");
    const calculateValue = await localStorage.getItem("calculateValue");
    const couponMsg = await localStorage.getItem("couponMsg");
    let CouponID = await localStorage.getItem("CouponID");

    let amount;
    if (couponMsg === "Coupon Applied") {
      if (calculateValue > 500) {
        amount = parseInt(calculateValue) - parseInt(CouponValue);
      } else {
        amount = parseInt(calculateValue) - parseInt(CouponValue) + 40;
      }
    } else {
      if (calculateValue > 500) {
        amount = parseInt(calculateValue);
      } else {
        amount = parseInt(calculateValue) + 40;
      }
    }
    const paymentDetails = {
      amount: amount + ".00",
      // amount: "1.00",
      billing_name: userAddress.first_name + " " + userAddress.last_name,
      address_id: AddressID,
      billing_address: userAddress.address_line,
      billing_city: userAddress.city,
      billing_state: userAddress.state,
      billing_zip: userAddress.pincode,
      billing_country: userAddress.country,
      billing_email: userAddress.email,
      billing_tel: userAddress.mobile_number,
      merchant_param1: CID,
      merchant_param2: AddressID,
      merchant_param3: formattedDate,
      merchant_param4: CouponID,
      merchant_param5: CouponValue,
    };

    // Create a form dynamically and submit it
    const form = document.createElement("form");
    form.method = "POST";
    form.action = ccAvenueUrl;

    // Create input elements for each payment detail
    for (const [key, value] of Object.entries(paymentDetails)) {
      const input = document.createElement("input");
      input.type = "hidden"; // Use hidden inputs
      input.name = key; // Set the name to the key
      input.value = value; // Set the value to the value
      form.appendChild(input); // Append to form
    }

    document.body.appendChild(form); // Append the form to the body
    form.submit(); // Submit the form
  };

  const handleGoBack = () => {
    setLoad(true);
    Navigate("/cart-page");
  };

  return (
    <>
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="py-10 lg:py-12 px-0 w-full xl:max-w-screen-xl flex flex-col md:flex-row lg:flex-row">
          <div className="md:w-full lg:w-3/5 flex h-full flex-col">
            <div className="md:col-span-2">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <div>
                <h1 className="mb-2 font-semibold text-2xl flex items-center">
                  <MdPayment className="mr-2" />
                  Payment
                </h1>
                <hr className="border border-emerald-500" />

                {/* Payment Method Selection */}
                <div className="p-4">
                  <h3 className="font-semibold text-gray-700 mb-4">
                    Select Payment Method
                  </h3>

                  {/* Cash on Delivery */}
                  {/* <div className="flex items-center mb-4">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="cod"
                      value="COD"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      defaultChecked
                    />
                    <IoIosCash className="ml-5 text-lg" />
                    <label
                      htmlFor="cod"
                      className="ml-2 text-lg font-semibold w-full"
                    >
                      Cash on Delivery
                    </label>
                  </div> */}

                  {/* PhonePe */}
                  <div className="flex items-center mb-4">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="phonepe"
                      value="PhonePe"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === "PhonePe"} // Automatically check PhonePe
                    />
                    <img
                      src="/phonepe.png"
                      alt="PhonePe Logo"
                      className="ml-5 h-6"
                    />
                    <label
                      htmlFor="phonepe"
                      className="ml-2 text-lg font-semibold w-full"
                    >
                      PhonePe
                    </label>
                  </div>

                  {/* CCAvenue */}
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="ccavenue"
                      value="CC Avenue"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <img
                      src="/CCAvenue.png"
                      alt="CCAvenue Logo"
                      className="ml-5 h-6"
                    />
                    <label
                      htmlFor="ccavenue"
                      className="ml-2 text-lg font-semibold w-full"
                    >
                      CC Avenue
                    </label>
                  </div>
                </div>

                {/* Buttons */}
                <div className="grid grid-cols-6 gap-4 lg:gap-6 mt-10">
                  <div className="col-span-6 sm:col-span-3">
                    <button
                      onClick={handleGoBack}
                      className="bg-indigo-50 border border-indigo-100 rounded py-3 text-center text-sm font-medium text-gray-700 hover:text-gray-800 hover:border-gray-300 transition-all flex justify-center font-serif w-full"
                    >
                      <IoReturnUpBackOutline className="text-xl mr-2" />
                      Continue Shopping
                    </button>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <button
                      onClick={() => {
                        if (paymentMethod === "PhonePe") {
                          phonePayPayment();
                        } else if (paymentMethod === "CC Avenue") {
                          ccAvenuePayment();
                        }
                        // else {
                        //   handleGenerateOrder();
                        // }
                      }}
                      type="submit"
                      className="bg-emerald-500 hover:bg-emerald-600 border border-emerald-500 transition-all rounded py-3 text-center text-sm font-sans font-medium text-white flex justify-center w-full"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default Payment;
